import { useEffect, useState } from 'react';
import { Team } from '@/graphql/generated-types';
import { LogoImage, DefaultLogo } from './index.styled';

export type TeamLogoProps = {
  team: Team;
  className?: string;
};

export function TeamLogo({ team: { logo, name }, className }: TeamLogoProps) {
  const [logoLoaded, setLogoLoaded] = useState(false);
  useEffect(() => {
    if (!logo) return;

    const img = new Image();
    img.src = logo;
    img.onload = () => setLogoLoaded(true);
  }, [logo]);

  return logo && logoLoaded ? (
    <LogoImage src={logo} alt="" title={name} className={className} />
  ) : (
    <DefaultLogo className={className} />
  );
}
