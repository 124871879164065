import { PropsWithChildren } from 'react';
import { ThemeSelect } from '@/components/theme-select';
import {
  Footer,
  FooterText,
  FooterMenu,
  FooterMenuLink,
  FooterMenuA,
  FooterThemeWrap,
  LayoutInner,
  LayoutS,
} from './index.styled';

export {
  Content,
  PageBlock,
  PageBlockContent,
  PageBlockTitle,
} from './index.styled';

export function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LayoutS>
      <LayoutInner>{children}</LayoutInner>
      <Footer>
        <FooterMenu>
          <FooterMenuLink to="/terms-of-service">
            Terms of service
          </FooterMenuLink>
          <FooterMenuLink to="/privacy">Privacy policy</FooterMenuLink>
          <FooterMenuA href="/collab">Collab</FooterMenuA>

          <FooterThemeWrap>
            <FooterText>Theme:</FooterText>
            <ThemeSelect />
          </FooterThemeWrap>
        </FooterMenu>

        <FooterText>
          &copy; 2020&ndash;{new Date().getFullYear()} Taskode. All rights
          reserved.
        </FooterText>
      </Footer>
    </LayoutS>
  );
}
