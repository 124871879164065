import styled, { css } from 'styled-components';
import { borderRadius, color } from '@/lib/styled';
import { Button } from '@/components/ui/button';
import { ReactComponent as InfoIconS } from '@/assets/icons/info.svg';
import { ReactComponent as SuccessIconS } from '@/assets/icons/check-circle.svg';
import { ReactComponent as ErrorIconS } from '@/assets/icons/x-circle.svg';

export const InlineAlertS = styled.div`
  display: flex;
  gap: 24px;
  margin: 8px 0;
  padding: 16px 24px;
  border-radius: ${borderRadius()};
  background-color: ${color('backgroundTertiary')};
`;

export const InlineAlertContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > h3 {
    margin: 0 0 16px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;

    &:only-child {
      margin: 0;
    }
  }
`;

export const InlineAlertButtons = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InlineAlertButtonS = styled(Button)`
  padding: 8px 12px;
`;

export const inlineAlertIconStyle = css`
  flex: 0 0 36px;
  width: 32px;
  height: 32px;
  margin-top: 4px;
`;

export const InfoIcon = styled(InfoIconS)`
  ${inlineAlertIconStyle};
  color: ${color('textSecondary')};
`;

export const SuccessIcon = styled(SuccessIconS)`
  ${inlineAlertIconStyle};
  color: ${color('success')};
`;

export const ErrorIcon = styled(ErrorIconS)`
  ${inlineAlertIconStyle};
  color: ${color('error')};
`;
