import {
  CSSProperties,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Portal } from 'react-portal';
import { getWindowSize, useResize } from '@/lib/dom';
import { KEY_ESCAPE } from '@/constants/keys';
import { PopupMenuContext } from './context';
import { PopupMenuS } from './index.styled';

export { PopupMenuContext };

export interface PopupMenuProps {
  anchor: RefObject<HTMLElement>;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnClick?: boolean;
  stretch?: boolean;
  className?: string;
  children: ReactNode;
}

export const PopupMenu = ({
  anchor,
  open,
  onOpen,
  onClose,
  closeOnClick = true,
  stretch,
  className,
  children,
}: PopupMenuProps) => {
  const [isOpen, setIsOpen] = useState(!!open);
  const ref = useRef<HTMLDivElement | null>(null);

  const clickInside = useCallback(() => {
    if (closeOnClick) setIsOpen(false);
  }, [closeOnClick, setIsOpen]);

  useEffect(() => {
    const el = anchor.current;
    if (!el) return () => {};

    const onClick = () => setIsOpen((state) => !state);
    el.addEventListener('click', onClick);

    const onInput = () => setIsOpen(true);
    el.addEventListener('input', onInput);

    return () => {
      el.removeEventListener('click', onClick);
      el.removeEventListener('input', onInput);
    };
  }, [anchor, setIsOpen]);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (!ref.current || !e.target || !isOpen) return;
      if (anchor.current && anchor.current.contains(e.target as Element))
        return;
      if (ref.current.contains(e.target as Element)) return;
      setIsOpen(false);
    };

    const onKey = (e: KeyboardEvent) => {
      if (!isOpen) return;
      if (e.code === KEY_ESCAPE) setIsOpen(false);
    };

    const onScroll = () => setIsOpen(false);

    window.addEventListener('click', onClick);
    window.addEventListener('keydown', onKey);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('click', onClick);
      window.removeEventListener('keydown', onKey);
      window.removeEventListener('scroll', onScroll);
    };
  }, [ref, anchor, isOpen, setIsOpen]);

  useResize(() => setIsOpen(false), [setIsOpen]);

  useEffect(() => {
    if (isOpen && onOpen) onOpen();
    if (!isOpen && onClose) onClose();
  }, [isOpen, onOpen, onClose]);

  const [minWidth, setMinWidth] = useState('0');
  const [style, setStyle] = useState<CSSProperties>({});

  useResize(() => {
    if (!anchor.current) return;

    if (stretch) {
      const { width } = anchor.current.getBoundingClientRect();
      setMinWidth(`${width}px`);
    } else {
      setMinWidth('0');
    }
  }, [stretch]);

  useResize(() => {
    if (!anchor.current || !ref.current || !isOpen) {
      setStyle({ minWidth });
      return;
    }

    const { width: windowWidth, height: windowHeight } = getWindowSize();
    const { left, right, top, bottom } = anchor.current.getBoundingClientRect();
    const { width: refWidth, height: refHeight } =
      ref.current.getBoundingClientRect();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isLeft =
      right + refWidth <= windowWidth ? false : left > right - windowWidth;
    const isUp =
      bottom + refHeight <= windowHeight ? false : top > bottom - windowHeight;

    setStyle({
      minWidth,
      left, // : isLeft ? left - refWidth : left,
      top: isUp ? top - refHeight : bottom,
    });
  }, [anchor, isOpen]);

  return (
    <PopupMenuContext.Provider value={{ isOpen, clickInside }}>
      <Portal>
        <PopupMenuS
          className={className}
          style={isOpen ? style : undefined}
          ref={ref}
        >
          {children}
        </PopupMenuS>
      </Portal>
    </PopupMenuContext.Provider>
  );
};
