import { ComponentType, ReactNode } from 'react';
import {
  InfoIcon,
  SuccessIcon,
  ErrorIcon,
  InlineAlertS,
  InlineAlertContent,
  InlineAlertButtons,
  InlineAlertButtonS,
  inlineAlertIconStyle,
} from './index.styled';

export { inlineAlertIconStyle };

export type AlertStatus = 'info' | 'success' | 'error';

export type InlineAlertButton = {
  text: string;
  onClick: () => void;
  primary?: boolean;
  disabled?: boolean;
};

export type InlineAlertProps = {
  title?: ReactNode;
  children?: ReactNode;
  status?: AlertStatus;
  icon?: ReactNode;
  buttons?: InlineAlertButton[];
  className?: string;
};

const statusIcons: Record<AlertStatus, ComponentType> = {
  info: InfoIcon,
  success: SuccessIcon,
  error: ErrorIcon,
};

export function InlineAlert({
  title,
  children,
  status = 'info',
  icon,
  buttons,
  className,
}: InlineAlertProps) {
  const Icon = statusIcons[status];

  return (
    <InlineAlertS className={className}>
      {icon ?? <Icon />}

      <InlineAlertContent>
        {title && <h3>{title}</h3>}
        {children}
      </InlineAlertContent>

      {buttons?.length && (
        <InlineAlertButtons>
          {buttons?.map(({ text, onClick, primary, disabled }) => (
            <InlineAlertButtonS
              key={text}
              onClick={onClick}
              primary={primary}
              disabled={disabled}
            >
              {text}
            </InlineAlertButtonS>
          ))}
        </InlineAlertButtons>
      )}
    </InlineAlertS>
  );
}
