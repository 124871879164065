import { useEffect, useState } from 'react';
import { useUserInfoQuery } from '@/graphql/generated-types';
import { InitialsAvatar } from './initials-avatar';
import { UserPic } from './index.styled';

async function loadImage(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = resolve;
    img.onerror = reject;
  });
}

export type UserAvatarProps = {
  userId: string;
  title?: string;
};

// eslint-disable-next-line prefer-arrow-callback
export function UserAvatar({ userId, title }: UserAvatarProps) {
  const { data } = useUserInfoQuery({
    variables: { id: userId },
    fetchPolicy: 'cache-first',
  });
  const [url, setUrl] = useState<string | null>(null);

  const user = data?.userInfo || { name: '', avatar: null };

  useEffect(() => {
    if (!user.avatar) return;

    loadImage(user.avatar)
      .then(() => setUrl(user.avatar ?? null))
      .catch(() => setUrl(null));
  }, [user.avatar]);

  return url ? (
    <UserPic alt={user.name} src={url} title={title ?? user.name} />
  ) : (
    <InitialsAvatar name={user.name} title={title ?? user.name} />
  );
}
