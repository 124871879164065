import { ReactNode, useRef, useState } from 'react';

import { useResize } from '@/lib/dom';
import { SubHeaderS, SubHeaderCopy } from './index.styled';

export interface SubHeaderProps {
  children: ReactNode;
}

export const SubHeader = ({ children }: SubHeaderProps) => {
  const [mode, setMode] = useState<'compact' | 'mobile' | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const fullRef = useRef<HTMLDivElement>(null);
  const compactRef = useRef<HTMLDivElement>(null);

  useResize(() => {
    if (!ref.current || !fullRef.current || !compactRef.current) return;

    const { width: w } = ref.current.getBoundingClientRect();
    const { width: fw } = fullRef.current.getBoundingClientRect();
    const { width: cw } = compactRef.current.getBoundingClientRect();

    if (w < cw) setMode('mobile');
    else if (w < fw) {
      setMode('compact');
    } else setMode(undefined);
  }, [fullRef, compactRef, mode, setMode]);

  return (
    <>
      <SubHeaderS ref={ref} mode={mode}>
        {children}
      </SubHeaderS>

      <SubHeaderCopy ref={fullRef}>{children}</SubHeaderCopy>

      <SubHeaderCopy ref={compactRef} mode="compact">
        {children}
      </SubHeaderCopy>
    </>
  );
};
