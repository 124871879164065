import { forwardRef, ReactNode, Ref, useCallback, useContext } from 'react';
import { PopupMenuContext } from '../context';
import {
  MenuItemLink,
  MenuItemButton,
  MenuItemDiv,
  MenuItemIcon,
  MenuItemContent,
} from './index.styled';

export interface MenuItemProps {
  href?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  icon?: ReactNode;
  isSelected?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

export const MenuItem = forwardRef(
  (
    {
      href,
      onClick,
      onMouseEnter,
      onMouseLeave,
      icon,
      isSelected,
      isActive,
      disabled,
      className,
      children,
    }: MenuItemProps,
    ref: Ref<any>
  ) => {
    const { clickInside } = useContext(PopupMenuContext);
    const onItemClick = useCallback(() => {
      if (onClick) onClick();
      clickInside();
    }, [onClick, clickInside]);

    if (href) {
      return (
        <MenuItemLink
          ref={ref}
          to={href}
          onClick={onItemClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={className}
          $isSelected={isSelected}
          $isActive={isActive}
          disabled={disabled}
        >
          {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
          <MenuItemContent>{children}</MenuItemContent>
        </MenuItemLink>
      );
    }

    if (onClick) {
      return (
        <MenuItemButton
          ref={ref}
          onClick={onItemClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={className}
          $isSelected={isSelected}
          $isActive={isActive}
          disabled={disabled}
        >
          {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
          <MenuItemContent>{children}</MenuItemContent>
        </MenuItemButton>
      );
    }

    return (
      <MenuItemDiv
        ref={ref}
        onClick={onItemClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        $isSelected={isSelected}
        $isActive={isActive}
        disabled={disabled}
      >
        {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
        <MenuItemContent>{children}</MenuItemContent>
      </MenuItemDiv>
    );
  }
);
