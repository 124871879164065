import { useEffect } from 'react';

declare global {
  interface Window {
    clientWidth: number;
    clientHeight: number;
  }

  interface Document {
    webkitFullscreenElement?: HTMLElement;
    mozFullScreenElement?: HTMLElement;
    msFullscreenElement?: HTMLElement;
    webkitExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;
    msExitFullscreen?: () => void;
  }

  interface Element {
    webkitRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
    msRequestFullscreen?: () => void;
  }
}

export function getWindowSize() {
  return {
    width: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    height: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
  };
}

export function useResize(
  callback: (width: number, height: number) => void,
  deps: any[]
) {
  useEffect(() => {
    const handleResize = () => callback(window.innerWidth, window.innerHeight);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function scrollTo(
  element: Element,
  options: ScrollIntoViewOptions = { behavior: 'smooth' }
) {
  element.scrollIntoView(options);
}

export function canUseDom() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

export function getFullscreenElement(): Element | undefined {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );
}

export function requestFullscreen(elem?: Element) {
  const element = elem || document.documentElement;

  if (getFullscreenElement()) {
    return;
  }

  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  }
}

export function exitFullscreen() {
  if (!getFullscreenElement()) return;
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}
