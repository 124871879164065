import styled from 'styled-components';

import { buttonStyle } from '@/components/ui/button';
import { PopupMenu } from '@/components/ui/popup-menu';

export const UserMenuS = styled.div`
  display: flex;
  align-items: center;
`;
export const UserWrap = styled.div`
  position: relative;
`;

export const SignInLink = styled.a`
  ${buttonStyle};

  display: flex;
  align-items: center;
  font-size: 14px;

  svg {
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 8px 0 -4px;
  }
`;

export const UserPopupMenu = styled(PopupMenu)``;
