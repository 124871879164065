import { createRoot } from 'react-dom/client';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
} from '@rollbar/react';
import { rollbar } from '@/lib/rollbar';
import * as logger from '@/lib/logger';
import { App, Providers } from '@/components/app';
import {
  ErrorBoundary,
  ErrorBoundaryFallback,
} from '@/components/error-boundary';

try {
  const rootElement = document.getElementById('root');
  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      rollbar ? (
        <RollbarProvider instance={rollbar}>
          <RollbarErrorBoundary fallbackUI={ErrorBoundaryFallback}>
            <Providers>
              <App />
            </Providers>
          </RollbarErrorBoundary>
        </RollbarProvider>
      ) : (
        <ErrorBoundary>
          <Providers>
            <App />
          </Providers>
        </ErrorBoundary>
      )
    );
  } else {
    logger.error('#root not found');
  }
} catch (e) {
  logger.exception(e);
}
