import { useCallback, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useUserTeamsQuery } from '@/graphql/generated-types';
import { SelectOptionItem } from '@/components/ui/select';
import { TeamLogo } from '@/components/team-logo';
// import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { TeamSelectS, TeamSelectWrap } from '@/components/header/index.styled';

export function TeamSelect() {
  const match = useMatch('/team/:teamId/*');
  const { teamId = 'dashboard' } = match?.params || {};

  const navigate = useNavigate();

  const { data, loading: teamsLoading } = useUserTeamsQuery();

  const options = useMemo<SelectOptionItem[]>(() => {
    const teams = data?.currentUser?.teams;
    if (!teams)
      return [
        {
          value: '',
          text: 'My teams',
        },
      ];

    const opts: SelectOptionItem[] = [];

    if (teams.length > 1) {
      opts.push({
        value: 'dashboard',
        text: 'My dashboard',
        icon: <TeamLogo team={{ id: '', name: '', logo: '' }} />,
      });
    }

    opts.push(
      ...teams.map((team) => ({
        value: team.id,
        text: team.name,
        icon: <TeamLogo team={team} />,
      }))
    );

    // if (teams.length === 0) {
    //   opts.push({
    //     value: 'register-team',
    //     text: 'Create new team',
    //     icon: <PlusIcon />,
    //   });
    // }

    return opts;
  }, [data?.currentUser?.teams]);

  const onSelect = useCallback(
    (value: string) => {
      if (!value) return;

      let redirectUrl = `/team/${value}/scheduled`;
      if (value === 'register-team') redirectUrl = '/register-team';
      if (value === 'dashboard') redirectUrl = '/dashboard';
      navigate(redirectUrl);
    },
    [navigate]
  );

  if (options.length === 0) return <div />;

  return (
    <TeamSelectWrap>
      <TeamSelectS
        value={teamId}
        options={options}
        onChange={onSelect}
        disabled={teamsLoading || options.length <= 1}
      />
    </TeamSelectWrap>
  );
}
