import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { LAYOUT_PADDING } from '@/constants/layout';
import { borderRadius, color } from '@/lib/styled';

export const LayoutS = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const LayoutInner = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

interface ContentProps {
  wide?: boolean;
}

export const Content = styled.div`
  width: 100%;
  max-width: ${(props: ContentProps) => (props.wide ? '1740px' : '1200px')};
  margin: 0 auto;
  padding: ${LAYOUT_PADDING};
  background-color: ${color('backgroundSecondary')};
`;

export const PageBlock = styled.div`
  margin-top: 8px;
`;

export const PageBlockContent = styled.div`
  padding: 16px 24px;
  border-radius: ${borderRadius()};
  background-color: ${color('backgroundPrimary')};
`;

export const PageBlockTitle = styled.h3`
  margin: 8px 0 16px;
  font-size: 16px;
  font-weight: 300;
  color: ${color('textSecondary')};
  text-transform: uppercase;
`;

const footerText = css`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${color('textPrimary')};
`;

export const Footer = styled.div`
  flex: 0 0 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding: 0 ${LAYOUT_PADDING};

  @media (min-width: 768px) {
    gap: 48px;
  }
`;

export const FooterMenu = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 36px;
  }
`;

export const FooterMenuLink = styled(Link)`
  ${footerText};
  text-decoration: none;
`;

export const FooterMenuA = styled.a`
  ${footerText};
  text-decoration: none;
`;

export const FooterText = styled.div`
  ${footerText};
`;

export const FooterThemeWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
