import { rollbar } from './rollbar';
/* eslint-disable no-console */

export const debug = (...args: any[]) => console.debug(...args);
export const info = (...args: any[]) => console.info(...args);
export const warn = (...args: any[]) => console.warn(...args);
export const error = (...args: any[]) => {
  console.error(...args);
  rollbar?.error(...args);
};
export const exception = (e: Error | unknown) => {
  if (e instanceof Error) {
    console.error(e.message);
    console.error(e.stack);
  } else {
    error(e);
  }
  rollbar?.error(e as Error);
};
