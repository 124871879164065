import { useRef } from 'react';
import { useUser } from '@/contexts/user';
import { Avatar } from '@/components/avatar';
import { MenuItem } from '@/components/ui/popup-menu/menu-item';
import { ReactComponent as LoginIcon } from '@/assets/icons/log-in.svg';
import { ReactComponent as ProfileIcon } from '@/assets/icons/sliders.svg';
import { ReactComponent as LogoutIcon } from '@/assets/icons/log-out.svg';
import { UserMenuS, SignInLink, UserWrap, UserPopupMenu } from './index.styled';

export function UserMenu() {
  const { loading, user, logout } = useUser();

  const userMenuRef = useRef(null);

  if (loading) {
    return <UserMenuS>loading...</UserMenuS>;
  }

  if (!user) {
    return (
      <SignInLink href="/auth/google" primary>
        <LoginIcon />
        Sign in
      </SignInLink>
    );
  }

  return (
    <UserMenuS>
      <UserWrap>
        <Avatar userId={user.id} ref={userMenuRef} />

        <UserPopupMenu anchor={userMenuRef} closeOnClick>
          <MenuItem href="/profile" icon={<ProfileIcon />}>
            Profile
          </MenuItem>
          <MenuItem onClick={logout} icon={<LogoutIcon />}>
            Sign out
          </MenuItem>
        </UserPopupMenu>
      </UserWrap>
    </UserMenuS>
  );
}
