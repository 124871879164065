import styled, { css } from 'styled-components';

import { ReactComponent as MonitorIcon } from '@/assets/icons/monitor.svg';

const logoStyle = css`
  display: block;
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 12px;
`;

export const LogoImage = styled.img`
  ${logoStyle};
`;

export const DefaultLogo = styled(MonitorIcon)`
  ${logoStyle};
`;
