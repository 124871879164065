import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { initTracking } from '@/lib/track';
import { load } from '@/contexts/loader';
import { CookieConsentContext } from '@/contexts/cookie-consent';
import { GlobalStyle } from '@/components/app/global-style';
import { Notifications } from '@/components/notifications';
import { Providers } from './providers';

export { Providers };

const LoginPageLoader = load(() => import('@/components/pages/login'));
const PrivacyPageLoader = load(() => import('@/components/pages/privacy'));
const TermsOfServicePageLoader = load(
  () => import('@/components/pages/terms-of-service')
);
const CollabEditorFallbackLoader = load(
  () => import('@/components/pages/collab/editor/fallback')
);
const Error404Loader = load(() => import('@/components/pages/error-404'));

const RegisterTeamPageLoader = load(
  () => import('@/components/pages/register-team')
);
const TeamPageLoader = load(() => import('@/components/pages/team'));
const InterviewPageLoader = load(() => import('@/components/pages/interview'));
const InterviewCodePage = load(
  () => import('@/components/pages/interview/code')
);
const ProfilePageLoader = load(() => import('@/components/pages/profile'));
const DashboardPageLoader = load(() => import('@/components/pages/dashboard'));
const CollabEditorLoader = load(
  () => import('@/components/pages/collab'),
  CollabEditorFallbackLoader
);

export function App() {
  const { settings } = useContext(CookieConsentContext);
  useEffect(() => {
    if (settings?.analytics) initTracking();
  }, [settings?.analytics]);
  return (
    <>
      <GlobalStyle />

      <Routes>
        <Route path="/login" Component={LoginPageLoader} />
        <Route path="/register-team" Component={RegisterTeamPageLoader} />

        <Route path="/privacy" Component={PrivacyPageLoader} />
        <Route path="/terms-of-service" Component={TermsOfServicePageLoader} />

        <Route path="/team/:teamId/*" Component={TeamPageLoader} />

        <Route
          path="/interview/:interviewId/*"
          Component={InterviewPageLoader}
        />

        <Route path="/ic/:code" Component={InterviewCodePage} />

        <Route path="/profile/*" Component={ProfilePageLoader} />

        <Route path="/dashboard/*" Component={DashboardPageLoader} />

        <Route path="/collab/*" Component={CollabEditorLoader} />

        <Route path="*" Component={Error404Loader} />
      </Routes>

      <Notifications />
    </>
  );
}
