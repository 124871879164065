import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { LoaderProvider } from '@/contexts/loader';
import { NotificationsProvider } from '@/contexts/notifications';
import { CookieConsentProvider } from '@/contexts/cookie-consent';
import { ThemeProvider } from '@/contexts/theme';
import { UserProvider } from '@/contexts/user';
import { apolloClient } from '@/lib/apollo';

export function Providers({ children }: PropsWithChildren<{}>) {
  return (
    <BrowserRouter>
      <LoaderProvider>
        <NotificationsProvider>
          <CookiesProvider>
            <CookieConsentProvider>
              <ThemeProvider>
                <UserProvider>
                  <ApolloProvider client={apolloClient}>
                    {children}
                  </ApolloProvider>
                </UserProvider>
              </ThemeProvider>
            </CookieConsentProvider>
          </CookiesProvider>
        </NotificationsProvider>
      </LoaderProvider>
    </BrowserRouter>
  );
}
