import { createContext } from 'react';

export interface PopupMenuContextValue {
  isOpen: boolean;
  clickInside: () => void;
}

export const PopupMenuContext = createContext<PopupMenuContextValue>({
  isOpen: false,
  clickInside() {},
});
