import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CookieSettings,
  getCookieSettings,
  setCookieSettings,
} from '@/lib/cookies';

export interface CookieConsentContextValue {
  settings?: CookieSettings;
  rejected: boolean;
  save: (settings: CookieSettings) => void;
  reject: () => void;
}

export const defaultCookieSettings = { analytics: true };

export const CookieConsentContext = createContext<CookieConsentContextValue>({
  rejected: false,
  save: () => {},
  reject: () => {},
});

export function CookieConsentProvider({ children }: PropsWithChildren) {
  const [settings, setSettings] = useState<CookieSettings | undefined>();

  const [rejected, setRejected] = useState(false);

  useEffect(() => {
    const s = getCookieSettings();
    if (s) setSettings(s);
  }, [setSettings]);

  const value = useMemo<CookieConsentContextValue>(
    () => ({
      settings,
      rejected,
      save: (s: CookieSettings) => {
        setRejected(false);
        setSettings(s);
        setCookieSettings(s);
      },
      reject: () => setRejected(true),
    }),
    [settings, setSettings, rejected, setRejected]
  );

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  );
}
