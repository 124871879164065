import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import * as logger from '@/lib/logger';
import { User } from '@/graphql/generated-types';
import { decodeToken } from '@/lib/jwt';
import { noop } from '@/lib/noop';
import { fetchRefreshToken, fetchLogout } from '@/lib/auth';

export type UserContextValue = {
  loading: boolean;
  user: User | null;
  logout: () => void;
  refresh: () => Promise<void>;
};

export const UserContext = createContext<UserContextValue>({
  loading: false,
  user: null,
  logout: noop,
  refresh: () => Promise.resolve(),
});

export function UserProvider({ children }: PropsWithChildren) {
  const [cookies] = useCookies(['accessToken']);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const refresh = useCallback(async () => {
    try {
      const { user: newUser } = await fetchRefreshToken();
      setUser(newUser);
    } catch (e) {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { payload } = decodeToken<User>(cookies.accessToken);
        setUser(payload);
      } catch (e) {
        await refresh();
      } finally {
        setLoading(false);
      }
    })();
  }, [cookies, refresh]);

  const logout = useCallback(async () => {
    try {
      await fetchLogout();
      setUser(null);
    } catch (e) {
      logger.info((e as Error).message);
    }
  }, []);

  const value = useMemo<UserContextValue>(
    () => ({ loading, user, logout, refresh }),
    [loading, user, logout, refresh]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext(UserContext);
