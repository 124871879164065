import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { conditionalColor, disabledProp } from '@/lib/styled';

type MenuItemProps = {
  $isSelected?: boolean;
  $isActive?: boolean;
  disabled?: boolean;
};

export const MenuItemIcon = styled.div`
  width: 24px;

  svg {
    display: block;
  }
`;

export const MenuItemContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const menuItemStyle = css`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 720px;
  padding: 12px;
  border: 0;
  outline: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;

  ${({ $isSelected }) => $isSelected && `font-weight: 600`};

  color: ${conditionalColor('buttonSecondaryText', {
    buttonPrimaryText: ({ $isSelected }: MenuItemProps) => $isSelected,
  })};

  background-color: ${conditionalColor('transparent', {
    buttonPrimaryBackground: ({ $isSelected }: MenuItemProps) => $isSelected,
  })};

  &:active,
  &:hover {
    background-color: ${conditionalColor('buttonSecondaryActiveBackground', {
      buttonPrimaryActiveBackground: ({ $isSelected }: MenuItemProps) =>
        $isSelected,
    })};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${conditionalColor('buttonSecondaryActiveBackground', {
        buttonPrimaryBackground: ({ $isSelected }: MenuItemProps) =>
          $isSelected,
      })};
    `}

  ${disabledProp};
`;

export const MenuItemLink = styled(Link)`
  ${menuItemStyle}
`;

export const MenuItemButton = styled.button.attrs(() => ({ type: 'button' }))`
  ${menuItemStyle}
`;

export const MenuItemDiv = styled.div`
  ${menuItemStyle}
`;
