import { forwardRef, ReactNode, Ref } from 'react';
import { UserAvatar } from './user-avatar';
import { InitialsAvatar } from './initials-avatar';
import { AvatarRoot, AvatarIcon } from './index.styled';

export type AvatarProps = {
  userId?: string;
  displayName?: string;
  className?: string;
  icon?: ReactNode;
  title?: string;
};

// eslint-disable-next-line prefer-arrow-callback
export const Avatar = forwardRef(function Avatar(
  { userId, displayName, className, icon, title }: AvatarProps,
  ref: Ref<any>
) {
  return (
    <AvatarRoot ref={ref} className={className}>
      {userId ? (
        <UserAvatar userId={userId} title={title ?? displayName} />
      ) : (
        <InitialsAvatar name={displayName ?? ''} title={title ?? displayName} />
      )}

      {icon && <AvatarIcon>{icon}</AvatarIcon>}
    </AvatarRoot>
  );
});
