import Color from 'color';
import { DefaultTheme } from 'styled-components';

const textPrimary = '#ffffff';
const textSecondary = '#f0f4f8';

const backgroundPrimary = '#3f3f3f';
const backgroundSecondary = '#282828';
const backgroundTertiary = Color(backgroundSecondary).lighten(0.02).toString();

const accent = '#397fe7';
const accentDarker = Color(accent).darken(0.1).toString();

const accent2 = '#ff615f';
const accent2Darker = Color(accent2).darken(0.1).toString();

const skeletonColor1 = Color(backgroundSecondary).lighten(0.02).toString();
const skeletonColor2 = Color(backgroundSecondary).lighten(0.05).toString();

const error = '#c71919';
const success = '#288818';

const border = '#606060';

export const colors = {
  transparent: 'transparent',

  textPrimary,
  textSecondary,

  accent,
  accent2,

  linkText: accent,

  backgroundPrimary,
  backgroundSecondary,
  backgroundTertiary,

  border: '#5a6a81',

  subHeaderBackground: '#2a2c2e',
  subHeaderText: textPrimary,
  subHeaderActiveText: textPrimary,
  subHeaderActiveBackground: backgroundSecondary,

  buttonPrimaryText: textSecondary,

  buttonPrimaryBackground: accent,
  buttonPrimaryActiveBackground: accentDarker,

  buttonPrimary2Background: accent2,
  buttonPrimary2ActiveBackground: accent2Darker,

  buttonSecondaryText: textPrimary,
  buttonSecondaryActiveText: textPrimary,
  buttonSecondaryBackground: backgroundTertiary,
  buttonSecondaryActiveBackground: Color(backgroundTertiary)
    .darken(0.1)
    .toString(),

  buttonGhostText: accent,

  errorText: '#8f0000',
  successText: success,

  checkboxBorder: border,
  checkboxCheck: '#394c68',

  inputText: textPrimary,
  inputBackground: backgroundTertiary,
  inputActiveBorder: accent,
  inputError: error,

  selectText: '#5a6a81',

  userAvatar1: '#8f0000',
  userAvatar2: '#c34300',
  userAvatar3: '#007038',
  userAvatar4: '#0043a6',
  userAvatar5: '#7d00be',
  userAvatar6: '#a3400d',
  userAvatar7: '#226875',
  userAvatar8: '#7c6c33',

  rateStar: '#f2994a',

  socketOffline: '#7b889a',
  socketConnecting: '#f2994a',
  socketConnected: success,
  socketError: '#8f0000',

  skeletonColor: skeletonColor1,
  skeletonGradient: `linear-gradient(
    90deg, ${skeletonColor1} 320px, 
    ${skeletonColor2} 640px, 
    ${skeletonColor1} 960px
  )`,

  videoBackground: '#626260',

  scrollbar: 'rgba(128, 128, 128, 0.25)',
};

export const boxShadows = [
  '0 1px 4px rgba(0, 0, 0, 0.5)',
  '0 1px 2px rgba(37, 40, 42, 0.25)',
];

export const borderRadius = 8;

export const darkTheme: DefaultTheme = { colors, boxShadows, borderRadius };
