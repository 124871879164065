import { Component, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import * as logger from '@/lib/logger';
import { lightTheme } from '@/constants/themes';
import { Content, Layout } from '@/components/layout';
import { PureHeader } from '@/components/header';

export function ErrorBoundaryFallback() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Layout>
        <PureHeader />
        <Content>
          <h1>Something went wrong.</h1>
        </Content>
      </Layout>
    </ThemeProvider>
  );
}

type ErrorBoundaryProps = PropsWithChildren<{}>;
type ErrorBoundaryState = { error?: Error };

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    logger.error(error);
    logger.error(errorInfo);
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorBoundaryFallback />;
    }

    return children;
  }
}
