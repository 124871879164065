import styled from 'styled-components';
import { color } from '@/lib/styled';
import { ReactComponent as LogoS } from '@/assets/taskode-logo.svg';

export const Logo = styled(LogoS)`
  display: block;
  height: 24px;
`;

export const InlineLogo = styled(Logo)`
  display: inline-block;
  height: 0.75em;
  margin: 0 0.5em;
  color: inherit;

  path {
    opacity: 1 !important;
  }

  #logo-k2 {
    fill: ${color('action')} !important;
  }
`;
